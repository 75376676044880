// lottieConfig.js

import animationData from '../lottie/loading_animation.json';

export const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
export default animationData;