// src/components/widgets/LottieAnimation.js

import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import debounce from 'lodash.debounce';
import animationData from './lottieConfig';

const LottieAnimation = ({ height, width, animationDataProp = animationData }) => {
  const [size, setSize] = useState({
    height: height || (window.innerWidth < 768 ? 300 : 450),
    width: width || (window.innerWidth < 768 ? 300 : 450),
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setSize({
        height: height || (window.innerWidth < 768 ? 300 : 450),
        width: width || (window.innerWidth < 768 ? 300 : 450),
      });
    }, 300); // Debounce delay of 300ms

    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel(); // Cancel any pending debounced calls
    };
  }, [height, width]);

  return (
    <Lottie
      animationData={animationDataProp}
      loop
      autoplay
      style={{ height: size.height, width: size.width }}
    />
  );
};

export default LottieAnimation;
