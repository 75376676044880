// src/App.js

import React, { Suspense, useEffect, useState, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import Navbar from './components/widgets/Navbar';
import Footer from './components/widgets/Footer';
import CookieBanner from './components/widgets/CookieBanner';
import ScrollToTopButton from './components/utils/ScrollToTopButton';
import SplashScreen from './components/widgets/SplashScreen';
import LottieAnimation from './components/widgets/LottieAnimation';
import { defaultOptions } from './components/widgets/lottieConfig';
import ErrorBoundary from './components/utils/ErrorBoundary';
import DefaultHelmet from './components/widgets/DefaultHelmet';

// Lazy loading route components
const MainPage = lazy(() => import('./components/pages/MainPage'));
const Teams = lazy(() => import('./components/pages/Teams'));
const Standings = lazy(() => import('./components/pages/Standings'));
const NewsPage = lazy(() => import('./components/pages/NewsPage'));
const Terminarz = lazy(() => import('./components/pages/Terminarz'));
const Rulebook = lazy(() => import('./components/pages/Rulebook'));
const PolitykaPrywatnosci = lazy(() => import('./components/pages/politykaPrywatnosci'));
const AboutUs = lazy(() => import('./components/pages/AboutUs'));
const NotFound = lazy(() => import('./components/utils/NotFound'));

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [isSplashScreenVisible, setSplashScreenVisible] = useState(true);
  const [darkMode, setDarkMode] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('theme') === 'dark';
    }
    return false; // Default to light mode
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const splashScreenShown = localStorage.getItem('splashScreenShown');
      if (splashScreenShown) {
        setSplashScreenVisible(false);
      }

      // Handle dark mode when app loads
      if (darkMode) {
        document.documentElement.classList.add('dark');
        localStorage.setItem('theme', 'dark');
      } else {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('theme', 'dark'); // Fixed to 'light'
      }
    }
  }, [darkMode]);

  const handleSplashScreenLoaded = () => {
    setSplashScreenVisible(false);
    if (typeof window !== 'undefined') {
      localStorage.setItem('splashScreenShown', 'true');
    }
  };

  const toggleDarkMode = () => setDarkMode((prevMode) => !prevMode);

  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        {isSplashScreenVisible ? (
          <SplashScreen onLoaded={handleSplashScreenLoaded} />
        ) : (
          <>
            <DefaultHelmet /> {/* Render DefaultHelmet */}
            <Navbar toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
            <div className="min-h-screen bg-gray-200 dark:bg-gray-950 py-4 md:py-6 shadow-lg  scroll-smooth">
              <ErrorBoundary>
                <Suspense
                  fallback={
                    <div className="flex justify-center items-center min-h-screen">
                      <LottieAnimation
                        options={defaultOptions}
                        height={300}
                        width={300}
                      />
                    </div>
                  }
                >
                  <Routes>
                    <Route path="/home" element={<Navigate to="/" replace />} />
                    <Route path="/" element={<MainPage />} />
                    <Route path="/druzyny" element={<Teams />} />
                    <Route path="/terminarz/:seasonSlug" element={<Terminarz />} />
                    <Route path="/tabela/:seasonSlug" element={<Standings />} />
                    <Route path="/aktualnosci" element={<NewsPage />} />
                    <Route path="/regulamin" element={<Rulebook />} />
                    <Route
                      path="/polityka-prywatnosci"
                      element={<PolitykaPrywatnosci />}
                    />
                    <Route path="/o-nas" element={<AboutUs />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </div>
            <Footer />
            <CookieBanner />
            <ScrollToTopButton />
          </>
        )}
      </Router>
    </HelmetProvider>
  );
}

export default App;
