import React, { useState, useEffect, Suspense } from 'react';

// Lazy load the volleyball icon to optimize performance
const FaVolleyballBall = React.lazy(() => 
  import('react-icons/fa').then(module => ({ default: module.FaVolleyballBall }))
);

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      const handleScroll = () => {
        if (window.scrollY > 150) {
          setIsVisible(true);
          window.removeEventListener('scroll', handleScroll);
        }
      };
      window.addEventListener('scroll', handleScroll);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ backgroundColor: '#1a1a1a', color: '#f5f5f5', padding: '1rem', position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 50 }}>
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <Suspense fallback={<div className="mr-4">Loading...</div>}>
            <FaVolleyballBall className="text-yellow-500 text-5xl md:text-3xl mr-4" />
          </Suspense>
          <p className="text-xs">
            Używamy plików cookie, aby zapewnić najlepsze wrażenia z użytkowania naszej strony. Kontynuując przeglądanie, wyrażasz zgodę na ich wykorzystanie.<br/>
            <a href="/politykaPrywatnosci" className="text-yellow-500 underline">
              Dowiedz się więcej
            </a>.
          </p>
        </div>
        <button
          onClick={handleAccept}
          className="bg-yellow-500 text-gray-900 font-semibold px-2 py-1 rounded-lg hover:bg-yellow-600 transition-colors text-sm"
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
