// src/components/widgets/DefaultHelmet.js

import React from 'react';
import { Helmet } from 'react-helmet-async';

const DefaultHelmet = () => (
  <Helmet>
    <html lang="pl" />
    <meta charSet="utf-8" />
    <link rel="icon" href={`${process.env.PUBLIC_URL}/fav_icon.webp`} />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#001f3f" />
    <meta
      name="description"
      content="Amatorska Liga Siatkówki - miejsce, gdzie pasja do siatkówki łączy pokolenia. Śledź aktualności, wyniki meczów i więcej. Zapraszamy do naszego sportowego świata!!!"
    />
    <meta
      name="keywords"
      content="siatkówka, amatorska liga, sport, rozgrywki, aktualności, terminarz, wyniki"
    />
    <meta name="author" content="Amatorska Liga Siatkówki" />

    <meta property="og:type" content="website" />
    <meta property="og:title" content="Amatorska Liga Siatkówki" />
    <meta
      property="og:description"
      content="Pasja, sport, i emocje. Dołącz do Amatorskiej Ligi Siatkówki i śledź wyniki, terminarz oraz aktualności."
    />
    <meta property="og:image" content={`${process.env.PUBLIC_URL}/ALS_logo.webp`} />
    <meta property="og:url" content="https://www.amatorskasiatka.pl" />
    <meta property="og:site_name" content="Amatorska Liga Siatkówki" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Amatorska Liga Siatkówki" />
    <meta
      name="twitter:description"
      content="Pasja, sport, i emocje. Dołącz do Amatorskiej Ligi Siatkówki i śledź wyniki, terminarz oraz aktualności."
    />
    <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/ALS_logo.webp`} />
    <meta name="twitter:site" content="@AmatorskaSiatka" />

    <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/ALS_logo.webp`} />

    <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest.json`} />

    <link rel="canonical" href="https://www.amatorskasiatka.pl" />

    <title>Amatorska Liga Siatkówki</title>
  </Helmet>
);

export default DefaultHelmet;
