import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (

    <footer className="bg-black text-white pb-6 px-2 space-y-1">

<div className="container mx-auto flex justify-center items-center py-4">
        {/* Center - Copyrigth and year */}
        <span className="text-xs md:text-sm">
          Copyright &#169; ALS {new Date().getFullYear()}
        </span>
        
</div>

      <div className="container mx-auto flex justify-between items-center px-4">
        

        {/* Left side - Privacy Policy link */}
        <span className="text-xs ">
          <Link to="/polityka-prywatnosci" className="hover:underline">
            Polityka prywatności
          </Link>
        </span>
        

        {/* Right side - Developer link */}
        <span className="text-xs">
         
          <a href="https://www.anvicit.com" target="_blank" rel="noopener noreferrer" className="hover:underline">
          Developed by{' '} AnVic IT
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
