// ./components/utils/ErrorBoundary.js

import React, { Component } from 'react';
import NotFound from './NotFound';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to display fallback UI on the next render
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log error details to an error reporting service here
    console.error("ErrorBoundary caught an error:", error, errorInfo);
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <NotFound message="Something went wrong. Please try again later." />;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
