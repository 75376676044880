import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-blue-900 to-blue-700 text-white">
      <img src={`${process.env.PUBLIC_URL}/404.webp`} alt="404" className="w-80 mb-8" />
      <h1 className="text-3xl mb-4">Hmm...</h1>
      <Link to="/" className="px-6 py-3 bg-yellow-500 rounded-md hover:bg-yellow-600 transition text-sky-950">
        Wróć do strony głównej
      </Link>
    </div>
  );
};

export default NotFound;
