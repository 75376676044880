// SplashScreen.js
import React, { useEffect } from 'react';
import LottieAnimation from './LottieAnimation'; // Assume this is your Lottie component

const SplashScreen = ({ onLoaded }) => {
  useEffect(() => {
    // Trigger the onLoaded callback as soon as the component mounts
    onLoaded();
  }, [onLoaded]);

  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <img src={`${process.env.PUBLIC_URL}/ALS_logo.webp`} alt="Logo" />
      <LottieAnimation />
    </div>
  );
};

export default SplashScreen;
